<template>
  <div class="config">
    <h1 class="mb-4">配置财商少年官网首页</h1>

    <el-form v-if="!isLogin" style="width: 500px">
      <h2>请先登录</h2>
      <el-form-item label="账号">
        <el-input v-model="loginForm.username"></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input type="password" v-model="loginForm.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="login">登录</el-button>
      </el-form-item>
    </el-form>

    <el-form label-width="200px" v-if="isLogin">
      <el-form-item v-for="(item, index) in config"
                    :key="index + 'foo'"
                    :label="item.label">
        <div v-if="item.value instanceof Array">
          <div class="d-flex mb-4" v-for="(img, imgIndex) in item.value" :key="imgIndex + 'bar'">
            <img class="config__img mr-4" :src="'https://oa.fqchildren.com/apis' + img.imgUrl">
            <el-button @click="removeImg(img, item, imgIndex)">删除图片</el-button>
          </div>
          <el-button @click="uploadImg(item)">新增图片</el-button>
        </div>
        <el-input v-model="item.value" v-if="typeof item.value === 'string'"></el-input>
        <el-switch v-model="item.value"
                   active-color="#13ce66"
                   inactive-color="#ff4949"
                   v-if="typeof item.value === 'boolean'" class="d-block my-2"></el-switch>
<!--        index是对象的key-->
        <span>{{index}}</span>
        <el-button class="mt-4 ml-4" @click="removeField(index)">删除上面这个字段</el-button>
      </el-form-item>
      <el-form-item label="编辑首页导师列表">
        <el-table :data="config.index_teachers" style="width: 70%; border: 1px solid #333333; display: inline-block">
          <el-table-column
            prop="name"
            label="导师姓名">
          </el-table-column>

          <el-table-column
            prop="area"
            label="地区">
          </el-table-column>

          <el-table-column
            label="头像">
            <template slot-scope="scope">
              <img v-if="scope.row.avatar.imgUrl"
                   :src="'https://oa.fqchildren.com/apis' + scope.row.avatar.imgUrl"
                   style="width: 100px; height: 100px;">
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="removeTeacher(scope.$index)" type="text">删除</el-button>
              <el-button @click="changeTeacher('name', scope.$index)" type="text">修改姓名</el-button>
              <el-button @click="changeTeacher('area', scope.$index)" type="text">修改地区</el-button>
              <el-button @click="changeTeacher('avatar', scope.$index)" type="text">修改头像</el-button>
              <el-button @click="moveTeacher(scope.$index, -1)" type="text" v-if="scope.$index !== 0">上移</el-button>
              <el-button @click="moveTeacher(scope.$index, 1)" type="text" v-if="scope.$index !== config.index_teachers.length - 1">下移</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-button @click="addTeacher">新增一个导师</el-button>
        </div>
      </el-form-item>

      <el-form-item class="text-center controller">
<!--        <el-button @click="addText">增加一个文字字段</el-button>-->
<!--        <el-button @click="addImage">增加一个图片字段</el-button>-->
        <el-button type="primary" @click="updateConfig">保存</el-button>
      </el-form-item>
    </el-form>

<!--    隐藏的图片上传入口-->
    <input type="file" ref="upload" style="visibility: hidden" @input="fileListChange">

<!--    新增字段弹出框-->
    <el-dialog
      title="新增一个字段"
      :visible.sync="dialogVisible"
      width="30%">
      <el-form>
        <el-form-item label="key">
          <el-input type="text" v-model="dialogKey"></el-input>
        </el-form-item>
        <el-form-item label="label">
          <el-input type="text" v-model="dialogLabel"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitDialog">确 定</el-button>
      </span>
    </el-dialog>

<!--    修改导师弹出框-->
    <el-dialog title="修改导师信息" :visible.sync="teacherDialogVisible" width="30%">
      <el-form>
        <el-form-item label="修改值">
          <el-input type="text" v-model="teacherDialogForm.value"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitTeacher()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import config from "@/views/edit/config";
  export default {
    name: "edit",
    data() {
      return {
        config,
        dialogVisible: false,
        dialogKey: '',
        dialogLabel: '',
        dialogType: '',
        uploadTarget: {}, // 当前在为哪个目标上传图片?
        teacherDialogVisible: false,
        teacherDialogForm: {
          index: -1,
          key: '', // 修改那个字段? 比如姓名
          value: '' // 改成什么? 比如张三
        },
        // 是否已登录
        isLogin: false,
        loginForm: {
          username: '',
          password: ''
        }
      }
    },
    methods: {
      // 上传图片
      uploadImg(item) {
        this.$refs.upload.click()
        this.uploadTarget = item
      },
      // 上传图片
      async fileListChange() {
        const imageReader = new FileReader()
        imageReader.readAsDataURL(this.$refs.upload.files[0])
        const base64 = await new Promise(resolve => {
          imageReader.onload = e => {
            resolve(e.target.result) // base64
          }
        })
        this.$axios.uploadImg({
          data: base64
        }).then(res => {
          if(typeof this.uploadTarget === 'number') { // 修改的是导师图片
            // 要同时删除旧图片
            let imgData = this.config.index_teachers[this.uploadTarget].avatar
            this.$axios.deleteImg({
              bucketName: imgData.bucketName,
              objectName: imgData.objectName,
              regionName: imgData.regionName
            })
            this.config.index_teachers[this.uploadTarget].avatar = res.data
          } else {
            this.uploadTarget.value.push(res.data) // 修改普通字段图片
          }
          this.updateConfig()
        })
      },
      // 删除图片
      removeImg(imgData, item, index) {
        this.$confirm('此操作将删除该图片, 是否继续?', '提示',).then(() => {
          this.$axios.deleteImg({
            bucketName: imgData.bucketName,
            objectName: imgData.objectName,
            regionName: imgData.regionName
          }).then(res => {
            if(res.code === 0) {
              this.$message({
                type: "success",
                message: '删除成功'
              })
            }
            item.value.splice(index, 1)
            this.updateConfig()
          })
        })
      },
      // 新增字段
      addText() {
        this.dialogKey = ''
        this.dialogLabel = ''
        this.dialogType = 'text'
        this.dialogVisible = true
      },
      // 新增图片字段
      addImage() {
        this.dialogKey = ''
        this.dialogLabel = ''
        this.dialogType = 'img'
        this.dialogVisible = true
      },
      submitDialog() {
        // 是否重复key
        if(this.config[this.dialogKey]) {
          this.$message({type: "error", message: '字段重复'})
          return
        }
        if(this.dialogType === 'text') {
          this.config[this.dialogKey] = {
            label: this.dialogLabel,
            value: ''
          }
        } else {
          this.config[this.dialogKey] = {
            label: this.dialogLabel,
            value: []
          }
        }
        this.dialogVisible = false
      },
      // 删除字段
      removeField(index) {
        this.$confirm('此操作将删除该字段, 是否继续?', '提示',).then(() => {
          delete this.config[index]
          this.$forceUpdate()
        })
      },
      // 修改导师信息
      changeTeacher(key, index) {
        if(key === 'avatar') {
          this.$refs.upload.click()
          this.uploadTarget = index // 修改uploadTarget 为 Number 类型
        } else {
          this.teacherDialogForm.key = key
          this.teacherDialogForm.index = index
          this.teacherDialogVisible = true
        }
      },
      // 提交导师信息修改
      submitTeacher() {
        this.config.index_teachers[this.teacherDialogForm.index][this.teacherDialogForm.key] = this.teacherDialogForm.value
        this.teacherDialogForm.value = ''
        this.teacherDialogForm.index = -1
        this.teacherDialogVisible = false
      },
      moveTeacher(index, step) {
        const target = this.config.index_teachers.splice(index, 1)[0]
        this.config.index_teachers.splice(index + step, 0, target)
        console.log(this.config.index_teachers)
      },
      // 删除导师
      removeTeacher(index) {
        this.$confirm('此操作将删除导师, 是否继续?', '提示').then(() => {
          if(this.config.index_teachers[index].avatar.imgUrl) {
            // 删除图片
            this.$axios.deleteImg({
              bucketName: this.config.index_teachers[index].avatar.bucketName,
              objectName: this.config.index_teachers[index].avatar.objectName,
              regionName: this.config.index_teachers[index].avatar.regionName
            }).then(res => {
              if(res.code === 0) {
                this.$message({
                  type: "success",
                  message: '删除图片成功'
                })
              }
            })
          }
          this.config.index_teachers.splice(index, 1)
          this.$message({
            type: "success",
            message: '删除导师成功'
          })
          this.updateConfig()
        })
      },
      // 新增导师
      addTeacher() {
        this.config.index_teachers.push({
          name: '',
          area: '',
          avatar: {}
        })
      },
      // 保存修改
      updateConfig() {
        this.$axios.updateConfig({
          version: 1,
          content: JSON.stringify(this.config),
          uid: 1403364743053376
        }).then(res => {
          if(res.code === 0) {
            this.$message({
              type: 'success',
              message: '已保存'
            })
          }
        })
      },
      login() {
        this.$axios.login({
          username: this.loginForm.username,
          password: this.loginForm.password
        }).then(res => {
          this.$message(res.msg)
          if(res.code === 0) {
            this.isLogin = true
          }
        })
      }
    },
    created() {
      this.$axios.getConfig({version: 1}).then(res => {
        this.config = JSON.parse(res.data.content)
      })
      // this.updateConfig()
    }
  }
  // 失去seo
  // 失去字体
</script>

<style lang="scss" scoped>
  .config {
    padding: 16px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  }

  .config__img {
    width: 200px;
  }

  .controller {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: #ffffff;
    width: 100%;
    padding: 16px 0;
    margin: 0;
    box-shadow: 0 0 5px #0068b7;
  }
</style>