export default {
  index_img_1_1: {
    label: '首页顶部轮播图',
    value: []
  },
  index_text_2_1: {
    label: '首页财商少年介绍第一行',
    value: '财商少年是一家以财商教育为基础的平台公司，'
  },
  index_text_2_2: {
    label: '首页财商少年介绍第二行',
    value: '通过财智大赢家系统学习，助力家庭财商教育成长。'
  },
  index_text_2_3: {
    label: '首页财商少年介绍第三行',
    value: '掌握财商智慧，成就美好未来。'
  },
  index_show_2: {
    label: '是否显示介绍',
    value: true
  },
  index_text_3_1: {
    label: '财智大赢家系统中文标题',
    value: '财智大赢家系统'
  },
  index_text_3_2: {
    label: '财智大赢家系统英文标题',
    value: 'Super FQ Winner System'
  },
  index_text_3_3: {
    label: '财智大赢家介绍第一行',
    value: '财智大赢家系统是由资深的导师专家团队，结合多年的课程研发、教学实践和机构合作经验打造而成。'
  },
  index_text_3_4: {
    label: '财智大赢家介绍第二行',
    value: '致力于将财商少年打造成行业标准制定者，让孩子从小培养财商思维，养成良好习惯。'
  },
  index_img_3_1: {
    label: '财智大赢家配图',
    value: []
  },
  index_show_3: {
    label: '是否显示财智大赢家模块',
    value: true
  },
  index_text_4_1: {
    label: '财商学院标题',
    value: '财商学院'
  },
  index_text_4_2: {
    label: '财商学院英文',
    value: 'Financial Quotient'
  },
  index_img_4_1: {
    label: '财商学院介绍图',
    value: []
  },
  index_show_4: {
    label: '是否显示财商学院',
    value: true
  },
  index_img_5_1: {
    label: '成长历程图',
    value: []
  },
  index_show_5: {
    label: '是否显示成长历程',
    value: true
  },
  index_text_6_1: {
    label: '四大课程体系标题中文',
    value: '四大课程体系'
  },
  index_text_6_2: {
    label: '四大课程体系标题英文',
    value: 'Four Major Curriculum Systems'
  },
  index_text_6_3: {
    label: '四大课程体系标题1',
    value: '《少儿财商启蒙》'
  },
  index_text_6_4: {
    label: '四大课程体系副标题1',
    value: '系列课程'
  },
  index_text_6_5: {
    label: '四大课程体系描述1-1',
    value: '适合人群：6-12岁儿童'
  },
  index_text_6_6: {
    label: '四大课程体系描述1-2',
    value: '课程介绍：寓教于乐，从游戏中理解财商概念'
  },
  index_img_6_1: {
    label: '四大课程体系图标1',
    value: []
  },
  // 2
  index_text_6_7: {
    label: '四大课程体系标题2',
    value: '《未来"CEO"》'
  },
  index_text_6_8: {
    label: '四大课程体系副标题2',
    value: '财商系列课程'
  },
  index_text_6_9: {
    label: '四大课程体系描述2-1',
    value: '适合人群：6-12岁儿童'
  },
  index_text_6_10: {
    label: '四大课程体系描述2-2',
    value: '课程介绍：系统学习，树立正确财商观念，培养理财习惯'
  },
  index_img_6_2: {
    label: '四大课程体系图标2',
    value: []
  },
  // 3
  index_text_6_11: {
    label: '四大课程体系标题3',
    value: '《家庭理财规划课堂》'
  },
  index_text_6_12: {
    label: '四大课程体系副标题3',
    value: '财商系列课程'
  },
  index_text_6_13: {
    label: '四大课程体系描述3-1',
    value: '适合人群：成人'
  },
  index_text_6_14: {
    label: '四大课程体系描述3-2',
    value: '课程介绍：贴近生活，清晰家庭财务，科学理财，合理投资'
  },
  index_img_6_3: {
    label: '四大课程体系图标3',
    value: []
  },

  // 4
  index_text_6_15: {
    label: '四大课程体系标题4',
    value: '《成人财商研修》'
  },
  index_text_6_16: {
    label: '四大课程体系副标题4',
    value: '财商系列课程'
  },
  index_text_6_17: {
    label: '四大课程体系描述4-1',
    value: '适合人群：理财师，财商教育从业意向者'
  },
  index_text_6_18: {
    label: '四大课程体系描述4-2',
    value: '课程介绍：连接国际金融理财学习平台，全方位掌握专业理财规划能力'
  },
  index_img_6_4: {
    label: '四大课程体系图标4',
    value: []
  },
  index_show_6: {
    label: '是否显示四大课程',
    value: true
  },

  index_img_7_1: {
    label: '导师1图片',
    value: []
  },
  index_img_7_2: {
    label: '导师2图片',
    value: []
  },
  index_img_7_3: {
    label: '导师3图片',
    value: []
  },
  index_img_7_4: {
    label: '导师1介绍图',
    value: []
  },
  index_img_7_5: {
    label: '导师2介绍图',
    value: []
  },
  index_img_7_6: {
    label: '导师3介绍图',
    value: []
  },
  index_show_7: {
    label: '是否显示导师模块',
    value: true
  },
  index_text_8_1: {
    label: '合作伙伴介绍1',
    value: '国际专业财富管理协会总部位于国际金融中心香港，是由多名大中华区域财富管理资深专家组成，专业从事财富管理理论研究和应用研究的国际化非盈利机构， 致力于推动财富管理领域的国际化教育，并推广国际适用的财富管理职业知识体系和认证标准。为各国家和地区培养现代金融领域管理所必须的专业理财、 风险管理、投资管理、资产管理等方面的中高级专业人才。'
  },
  index_text_8_2: {
    label: '合作伙伴介绍2',
    value: '广东财经大学是广东和华南地区重要的经、管、法人人才培养、科学研究和社会服务基地，是广东省内唯一具有硕士授予权的财经类院校，拥有华南地区 高校最齐全的财经类学科专业体系，在华南地区高校与全国财经、政法类院校中具有较高知名度和行业影响力。'
  },
  index_text_8_3: {
    label: '合作伙伴介绍3',
    value: '广东轻工职业技术学院前身是创建于1933年的“广东省立第一职业学校”，2004年被教育部、建设部确认为国际建设类技能型紧缺人才培养试点高校。 2008年被列为国际示范性高等职业院校建设单位；2011年，已优秀等级通过教育部、财政部组织的示范验收；2016年，被列为广东省一流高职院校建设单位。'
  },
  index_img_8_1: {
    label: '合作伙伴图片1',
    value: []
  },
  index_img_8_2: {
    label: '合作伙伴图片2',
    value: []
  },
  index_img_8_3: {
    label: '合作伙伴图片3',
    value: []
  },
  index_show_8: {
    label: '是否显示合作伙伴',
    value: true
  }


  // 导师管理

}